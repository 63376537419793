import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function Terms() {
  return (
    <div style={{ padding: 15, background: "white" }}>
      <Row>
        <Col>
          <Col></Col>
          <Col xs={12}>
            <h1>One Do App Terms of Use</h1>
            <p>Last Updated: May 31, 2023</p>
            <p>
              These supplemental Terms of Use ("Terms") apply to the One Do
              App (the “App”) provided by ESOTECH LTD company (the
              "Company"). By using the App, you agree to the terms below.
            </p>
            <h4>1. Eligibility and Use Requirements</h4>
            <p>
              To use the App, you must have a current account, and you must be a
              signed-in user. Your use of the App is governed by these terms
              (this "Agreement"), as well as any other applicable agreements,
              which are incorporated by reference. If there is any conflict
              between this Agreement and any other agreements, the conflicting
              provision in this Agreement will control.
            </p>
            <h4>2. Privacy Policy</h4>
            <p>
              Your use of the App is also subject to the Company's Privacy
              Policy, which describes our collection, use, and disclosure of
              information relating to your use of the App. You can review the Terms of Use at: <a href="https://www.1stdo.com/#/privacy">https://www.1stdo.com/#/privacy</a>.
            </p>
            <h4>3. Using the App</h4>
            <p>
              The App allows you to submit text inputs (“Inputs”) and may
              generate text or image content (“Content”) based on your Inputs.
              Your use of the App must comply with the Code of Conduct specified
              in Section 10 of this Agreement. By using the App, you are
              affirming that you have read the Terms of Use and Code of Conduct,
              understand them, and agree to abide by them.
            </p>
            <p>
              By using the App, you acknowledge that you are also subject to and accept OpenAI's Terms of Use for its services. You can review the Terms of Use at: <a href="https://openai.com/policies/terms-of-use">https://openai.com/policies/terms-of-use</a>.
            </p>
            <p>
              The App is available for use in countries where OpenAI provides its services. However, we do not provide services to countries where OpenAI is restricted or prohibited by law. By using the App, you affirm that you are accessing and using the App from a country where OpenAI's services are available and not in violation of any applicable laws or regulations.
            </p>
            <p>
              If you are accessing or using the App from a country where OpenAI's services are restricted or prohibited, you are solely responsible for complying with your local laws and regulations, and you should discontinue your use of the App.
            </p>
            <h4>4. Content Use and Ownership</h4>
            <p>
              Subject to your compliance with this Agreement and the Code of
              Conduct, you may use Content generated by the App for any legal
              personal, non-commercial purpose. The Company does not claim
              ownership of Inputs, Content, or any other content you provide,
              post, input, or submit to, or receive from, the App. However, by
              using the App and submitting content, you are granting the
              Company, its affiliated companies, and third-party partners
              permission to use the Inputs, Content, and related content in
              connection with the operation of its businesses, including the
              App.
            </p>
            <h4>
              5. No Guarantees; No Representations or Warranties;
              Indemnification by You
            </h4>
            <p>
              Without limiting any other provision of this Agreement, the
              Company does not make any warranty or representation of any kind
              that any material created by the App does not infringe the rights
              of any third party in any subsequent use of the content you may
              use (including but not limited to copyright, trademark, rights of
              privacy and publicity, and defamation). You must use any content
              from the App in accordance with applicable law and subject to any
              third-party rights. In addition, you agree to indemnify and hold
              harmless the Company, its affiliates, employees, and any other
              agents from and against anyclaims, losses, and expenses (including
              attorneys’ fees) arising from or relating to your use of the App,
              including your subsequent use of any content from the App and your
              breach of these Terms, the Code of Conduct, or violation
              of applicable law.
            </p>

            <h4>6. Suspension and Cancellation</h4>
            <p>
              We reserve the right to suspend or discontinue offering or
              supporting all or part of the App at any time and for any reason,
              to some or to all customers. Subject to the requirements of law,
              we may limit, suspend, or terminate your use of the App at any
              time without notice and for any reason, including (without
              limitation) if you breach this Agreement (including by failing to
              comply with the Code of Conduct), if we suspect you are engaged in
              fraudulent or illegal activity, or if your account is suspended or
              closed by us or by you.
            </p>
            <h4>7. Code of Conduct</h4>
            <p>By using the App, you agree:</p>
            <ol>
              <li>
                Not to engage in activity that is harmful to you, the App, or
                others. Do not attempt to create or share content that could be
                used to harass, bully, abuse, threaten, or intimidate other
                individuals, or otherwise cause harm to individuals,
                organizations, or society.
              </li>
              <li>
                Not to engage in activity that violates the privacy of others.
                Do not attempt to create or share content that could violate the
                privacy of others, including disclosure of private information
                (sometimes known as “doxing”).
              </li>
              <li>
                Not to engage in activity that is fraudulent, false, or
                misleading. Do not attempt to create or share content that could
                mislead or deceive others, including for example creation of
                disinformation, content enabling fraud, or deceptive
                impersonation.
              </li>
              <li>
                Not to infringe on the rights of others. Do not attempt to use
                the App to infringe on others’ legal rights, including
                intellectual property rights.
              </li>
              <li>
                Not to use the service to create or share inappropriate content
                or material. The Company does not permit the use of the App to
                create or share adult content, violence or gore, hateful
                content, terrorism and violent extremist content, glorification
                of violence, child sexual exploitation or abuse material, or
                content that is otherwise disturbing or offensive.
              </li>
              <li>
                Not to do anything illegal. Your use of the App must comply with
                applicable laws.
              </li>
            </ol>
            <h4>8. Content and Moderation</h4>
            <p>
              The App may block Inputs that violate the Code of Conduct or that
              are likely to lead to the creation of material that violates the
              Code of Conduct. Generated content that violates the Code of
              Conduct may be removed. Abuse of the App, such as repeated
              attempts to produce prohibited content or other violations of the
              Code of Conduct, may result in service or account suspension.
              Users can report problematic content via the App's reporting
              function.
            </p>
            <h4>9. Prohibited Uses of the App</h4>
            <p>We do not allow the use of our app for the following activities:</p>
            <ul>
              <li>
                <strong>Illegal Activity:</strong> You are prohibited from using the App for any illegal activities. This includes but is not limited to engaging in activities that are prohibited by local, national, or international laws and regulations.
              </li>
              <li>
                <strong>Child Sexual Abuse Material:</strong> The creation, dissemination, or use of any content that exploits or harms children, including child sexual abuse material (CSAM), is strictly prohibited. If we become aware of any such content, we will report it to the relevant authorities, such as the National Center for Missing and Exploited Children.
              </li>
              <li>
                <strong>Hateful, Harassing, or Violent Content:</strong> You are not allowed to generate or share content that expresses, incites, or promotes hate based on identity. This includes content that intends to harass, threaten, or bully individuals or groups. Furthermore, generating or sharing content that promotes or glorifies violence, or celebrates the suffering or humiliation of others, is strictly prohibited.
              </li>
              <li>
                <strong>Malware Generation:</strong> The App must not be used to generate content that attempts to create or distribute malware. This includes any code or content designed to disrupt, damage, or gain unauthorized access to computer systems.
              </li>
              <li>
                <strong>Activities with High Risk of Physical Harm:</strong> You are prohibited from using the App for activities that have a high risk of physical harm. This includes activities such as weapons development, military and warfare-related activities, and the management or operation of critical infrastructure in energy, transportation, and water sectors. Additionally, generating or sharing content that promotes, encourages, or depicts acts of self-harm, such as suicide, cutting, or eating disorders, is strictly prohibited.
              </li>
              <li>
                <strong>Activities with High Risk of Economic Harm:</strong> The App must not be used for activities that have a high risk of causing economic harm. This includes engaging in multi-level marketing, gambling, payday lending, and automated determinations of eligibility for credit, employment, educational institutions, or public assistance services.
              </li>
              <li>
                <strong>Fraudulent or Deceptive Activity:</strong> You are prohibited from using the App for any fraudulent or deceptive activities. This includes engaging in scams, coordinated inauthentic behavior, plagiarism, academic dishonesty, astroturfing (e.g., fake grassroots support or fake review generation), disinformation, spam, or the promotion of pseudo-pharmaceuticals.
              </li>
              <li>
                <strong>Adult Content and Dating Apps:</strong> The App must not be used to generate or share adult content, adult industries-related content, or content related to dating apps. This includes content meant to arouse sexual excitement, such as the description of sexual activity or the promotion of sexual services (excluding sex education and wellness). Erotic chat, pornography, and explicit adult material are strictly prohibited.
              </li>
              <li>
                <strong>Political Campaigning or Lobbying:</strong> You are not allowed to use the App to generate high volumes of campaign materials, generate campaign materials personalized to or targeted at specific demographics, build conversational or interactive systems (such as chatbots) that provide information about campaigns or engage in political advocacy or lobbying. Additionally, building products for political campaigning or lobbying purposes using the App is prohibited.
              </li>
              <li>
                <strong>Violation of Privacy:</strong> You must not use the App for activities that violate the privacy of individuals. This includes tracking or monitoring an individual without their consent, engaging in facial recognition of private individuals, classifying individuals based on protected characteristics, using biometrics for identification or assessment, and engaging in the unlawful collection or disclosure of personal identifiable information or protected records.
              </li>
              <li>
                <strong>Unauthorized Practice of Law or Financial Advice:</strong> The App must not be used for the unauthorized practice of law or offering tailored legal advice without a qualified person reviewing the information. Similarly, it must not be used for offering tailored financial advice without a qualified person reviewing the information. OpenAI's models are not fine-tuned to provide legal or financial advice, and users should not rely on the models as the sole source of such advice.
              </li>
              <li>
                <strong>Health Conditions and Medical Treatment:</strong> You should not use the App to tell someone that they have or do not have a certain health condition or provide instructions on how to cure or treat a health condition. OpenAI's models are not fine-tuned to provide medical information or diagnostic or treatment services for serious medical conditions. The App should not be used to triage or manage life-threatening issues that require immediate attention.
              </li>
              <li>
                <strong>High-Risk Government Decision-Making:</strong> The App must not be used for high-risk government decision-making, including activities related to law enforcement and criminal justice, migration and asylum, or any other activities that have significant legal, social, or political implications.
              </li>
            </ul>
            <p>Please note that this list of prohibited uses is not exhaustive, and we reserve the right to take appropriate action, including but not limited to suspending or terminating your use of the App, if we determine that you have engaged in any prohibited activities or violated any terms of this Agreement.</p>
            <h4>10. Sharing & Publication Policy</h4>
            <p>Our App fully complies with the guidelines published by OpenAI regarding the sharing and publication of content generated using our models. Please adhere to the following guidelines:</p>
            <p>For the most up-to-date version of OpenAI policy, please refer to: <a href="https://openai.com/policies/sharing-publication-policy">https://openai.com/policies/sharing-publication-policy</a></p>
           
            <p>Please note that the sharing and publication policy provided here is subject to change, and it is your responsibility to stay updated with any revisions. </p>
            <h4>11. App Subscription and Refund Policy</h4>
            <p>
              11.1 Subscription: The App may offer subscription plans with recurring payments. By subscribing to the App, you agree to pay the applicable subscription fees and authorize the Company to charge your chosen payment method on a recurring basis until you cancel your subscription.
            </p>
            <p>
              11.2 Cancellation: You can cancel your subscription at any time by following the cancellation instructions provided within the App or through the platform from which you made the subscription purchase. Upon cancellation, your access to the subscription features will be terminated at the end of the current billing cycle.
            </p>
            <p>
              11.3 Refunds: Our refund policy follows the refund policy of the respective platform from which you made the subscription purchase. If you subscribed to the App through the Google Play Store, the refund policy of the Google Play Store applies. Please refer to the Google Play Store's refund policy for more information on eligibility, timelines, and procedures for requesting a refund.
            </p>
            <h4>12. Compliance with Platform Policy</h4>
            <p>
              Our App is intended for use on various technology platforms, app stores, and other platforms. However, your use of the App must comply with the respective platform policies and guidelines of the platform on which you access and use the App. You are responsible for understanding and adhering to the policies and guidelines set forth by the platform providers.
            </p>
            <p>
              If you have any questions regarding the prohibited uses of the App or the compliance with platform policies, please contact us at hello@1stso.com.
            </p>
          </Col>
          <Col></Col>
        </Col>
      </Row>
    </div>
  );
}

export default Terms;
