import React from "react";
import styles from "../styles/banner.module.scss";
import Button from "./Button";

function Banner() {

  const handleClick = () => {
    window.open("https://play.google.com/store/apps/details?id=com.anacc2362193621.OneDo");
  };

  const handleDownload = () => {
    window.location.href = "/onedo.txt";
  };

  return (
    <>
      <div className={styles.mainContent}>
        <div className={styles.img}>
          <img src="/main.png" alt="hero" style={{ width: "100%", height: "auto" }} />
        </div>
        <div className={styles.container}>
          <img src='/title.svg' alt='title' style={{ width: "100%", height: "auto" }} />
          <hr />
          <h5>
            Unlock the potential of our Next-Gen Intelligent Core software
          </h5>
          <br></br>
          <p>
            Harnessing the power of intelligent algorithms to unlock unparalleled performance and efficiency.
          </p>
          <Button src="/play.svg" title="Download Now" onClick={handleClick} />
        
        </div>
      </div>
    </>
  );
}

export default Banner;
