
import styles from "../styles/footer.module.scss";

//footer
function Footer() {
  return (
    <div className={styles.footer}>
      <p className={styles.footerEndText}>Copyright©2023-2024 1stdo.com All Rights Reserved</p>
    </div>
  );
}

export default Footer;
