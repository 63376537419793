import React, { useState } from 'react';
import axios from 'axios';
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Form, Button, Alert } from 'react-bootstrap';
import './forget.css'

const apiUrl = 'https://www.1stdo.com:8080';

function ForgotPassword() {
  const { t, i18n } = useTranslation();

  const { token } = useParams();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const [username, setUsername] = useState('');
  const [message, setMessage] = useState('');

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await axios.post(`${apiUrl}/auth/forgot-password`, { username });
      setMessage('A password reset email has been sent to your inbox.');
    } catch (error) {
      setMessage('Error: Could not send the password reset email.');
    }
  };

  return (
    <>
      {message && <Alert variant="info" style={{zIndex:999}}>{message}</Alert>}
    <div className="forgot-password-box">
      <h1> {t("Forgot Password")}</h1>
      <Form onSubmit={handleSubmit} style={{ display: 'grid' }}>
        <Form.Group controlId="formUsername">
          <Form.Label> {t("Email")}</Form.Label>
          <div className="user-box">
            <Form.Control
              type="text"
              value={username}
              onChange={handleUsernameChange}
            />
          </div>
        </Form.Group>

        <button type="submit" className="forgot-password-button">
        {t("Submit")}
        </button>
      </Form>
    
    </div></>
  );
}

export default ForgotPassword;