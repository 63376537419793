import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Alert, Card, message, Spin } from "antd";
import axios from "axios";
import { useTranslation } from "react-i18next";
import '../Login/login.css'
import Logo from '../static/mainlogo.svg'

const apiUrl = 'https://www.1stdo.com:8080';

const EmailVerification = () => {

  const { token } = useParams();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [verificationCompleted, setVerificationCompleted] = useState(false); // New state

  const { t, i18n } = useTranslation();

  const handleVerifyEmail = async () => {
    setLoading(true);
    try {
      await axios.post(`${apiUrl}/auth/email-verification`, {
        token,
      });
      message.success("Email verification successful!");
      setVerificationCompleted(true); // Update verification status
      // history.push("/login");
    } catch (err) {
      console.log(err);
      setError(true);
      message.error("Verification Failed: There was an error verifying your email. Please try again later or contact support.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleVerifyEmail();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
  
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
           
      <Card title="Email Verification" style={{ textAlign: 'center' ,margin:30}}>
      <img src={Logo} alt="Logo" style={{ width: "200px", height: "auto", marginBottom: "20px" }} /> 
      <Spin spinning={loading}>
          {error ? (
            <Alert
              message="Verification Failed"
              description="There was an error verifying your email. Please try again later or contact support."
              type="error"
              showIcon
            />
          ) : (
            <p style={{ fontSize:20, fontWeight:"bold",color:"green" }}>{verificationCompleted ? "Email verification successful!" : "Please wait while we are verifying your email..."}</p> // Updated message
          )}
        </Spin>
       
      </Card>
    </div>
 </>

  );
};

export default EmailVerification;
