import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import './reset.css';
import Logo from '../static/password.svg'

const apiUrl = 'https://www.1stdo.com:8080';

const ResetPassword = () => {
  const location = useLocation();

  const { token } = useParams();
  const [password, setPassword] = useState('');
  const [isResetSuccessful, setIsResetSuccessful] = useState(false);  // 新增状态

  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${apiUrl}/auth/reset-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token, password }),
      });

      if (response.ok) {
        setIsResetSuccessful(true);  // 如果成功，设置状态为 true
      } else {
        alert('Failed to reset password');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
        <>

        <div style={{backgroundColor:"rgba(240,255,255,0.2)",height:'80vh',padding:'20%'}}>      
          <img src={Logo} alt="Logo" style={{ width: "200px", height: "auto", marginBottom: "20px" }} /> 
          <h1>Reset Password</h1>
          {isResetSuccessful ? (  // 根据状态渲染不同内容
            <p>Reset Successful.</p>
          ) : (
            <Form onSubmit={handleResetPassword} style={{ display: "grid" }}>
              <Form.Group controlId="formPassword">
                <div class="user-box">
                  <Form.Control
                    placeholder='Input your new password'
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>
                <div>
                  <button type="submit" className="resetbutton">
                    Continue
                  </button>
                </div>
              </Form.Group>
            </Form>
          )}
        </div>
          </>

  );
};

export default ResetPassword;



