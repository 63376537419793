import React, { useEffect, useState } from "react";
import styles from "../styles/card.module.scss";

const Card = ({ imgSrc, title }) => {
  return (
    <>
      <div className={styles.card}>
        <div className={styles.games}>
        <img src={imgSrc} alt={title} width="300" height="300" />
        </div>
        <div className={styles.details}>
          <h1>{title}</h1>
        </div>
      </div>
    </>
  );
};

export default Card;
