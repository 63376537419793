import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";

import withUpdateWindowSize from "./withUpdateWindowSize";
import Privacy from "./legal/privacy";
import Terms from "./legal/terms";
import ForgotPassword from "./ForgetPassword";
import EmailVerification from "./Register/EmailVerification";
import ResetPassword from "./ResetPassword";

import Home from "./home/page";
import Features from "./features/page";
import Header from "./component/Header";
import Footer from "./component/Footer";

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/features" element={<Features />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/email-verification/:token" element={<EmailVerification />} />
        <Route path="/forget-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="*" element={<Home />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default withUpdateWindowSize(App);
