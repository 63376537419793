
import styles from "../styles/brain.module.scss";
import Accordion from 'react-bootstrap/Accordion';

const accordionData = [
  {
    "key": "0",
    "header": "Generation and Creativity",
    "body": "Breathe life into ideas with our AI's innovative content generation and creative capabilities."
  },
  {
    "key": "1",
    "header": "Multi-turn Dialogue",
    "body": "Foster more engaging and meaningful interactions with our AI's ability to conduct multi-turn dialogues."
  },
  {
    "key": "2",
    "header": "Code Writing",
    "body": "Simplify your programming tasks with our AI's code writing functionality, turning your ideas into code efficiently."
  },
  {
    "key": "3",
    "header": "Multilingual Translation",
    "body": "Break down language barriers with our AI's capacity to translate multiple languages accurately and contextually."
  },
  {
    "key": "4",
    "header": "Text Rewriting",
    "body": "Enhance your message's clarity and effectiveness with our AI's ability to rewrite text for optimal readability and engagement."
  },
  {
    "key": "5",
    "header": "Natural Language Understanding",
    "body": "Unlock the power of language data with our AI's in-depth comprehension of human language."
  },
  {
    "key": "6",
    "header": "Logical Reasoning",
    "body": "Make informed decisions using our AI's logical reasoning capability, which processes information and provides sensible outputs."
  },
  {
    "key": "7",
    "header": "Knowledge-based Question Answering",
    "body": "Get reliable, fact-checked responses to your queries with our AI's extensive knowledge-based answering system."
  },

  {
    "key": "10",
    "header": "Sentiment Analysis",
    "body": "Enables OneDo to empathize with your emotions by understanding and analyzing the sentiment embedded in text, making communication feel more personal and less robotic."
  },

 
  {
    "key": "13",
    "header": "Context Awareness",
    "body": "With a grasp of context, OneDo keeps up with evolving conversations, ensuring your chat threads flow smoothly and naturally."
  },
  {
    "key": "14",
    "header": "Information Extraction",
    "body": "OneDo reads between the lines, extracting and summarizing key information from complex text, turning data overload into digestible insights."
  },
  {
    "key": "15",
    "header": "Self-learning",
    "body": "Despite being pre-trained on a fixed dataset, the app learns and adapts through user interactions, improving its assistance with every conversation."
  },
 
  {
    "key": "17",
    "header": "Personalization",
    "body": "OneDo molds itself to match your language style and preferences, providing a tailored chat experience just for you."
  },
  {
    "key": "18",
    "header": "Long Text Generation",
    "body": "From captivating stories to insightful reports, ChatGPT's ability to generate coherent long-form text truly expands the horizons of creativity."
  }
]



const Brain = () => {
  return (
    <>
      <div className={styles.brainContainer}>
        <div className={styles.largeTitle}>
          <img src="/title2.svg" alt="hero" width={300} height={120} />
        </div>
        <h4 className={styles.title}>Top-tier language model encompassing ten major capabilities and hundreds of specialized features.</h4>
        <div className={styles.brainimg}>
          <img src={'/brain.png'} alt={'brain'} width={"100%"}  style={{ maxWidth: '100%', height: 'auto' }} />
        </div>
      </div>
      <div className={styles.mobileContainer}>
        
        <Accordion defaultActiveKey="0" className={styles.accordionContainer}>
          {accordionData.map((item) => (
            <Accordion.Item key={item.key} eventKey={item.key} className={styles.accordionItem}>
              <Accordion.Header><h6>{item.header}</h6></Accordion.Header>
              <Accordion.Body><p>{item.body}</p></Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </div>
    </>
  );
};

export default Brain;
