import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function Privacy() {
  return (
    <div style={{ padding: 15, background: "white" }}>
      <Row>
        <Col>
          <Col></Col>
          <Col xs={12}>
            <h1>One Do App Privacy Policy</h1>
            <p>Last updated: May 31,2023</p>
            <p>
              ESOTECH LTD ("we", "us", or "our") operates the One Do App
              (the "App"). This Privacy Policy informs you of our policies
              regarding the collection, use, and disclosure of Personal
              Information we receive from users of the App.
            </p>
            <p>
              By using the App, you agree to the collection and use of
              information in accordance with this policy.
            </p>
            <h4>Information Collection and Use</h4>
            <p>
              While using our App, we may ask you to provide us with certain
              personally identifiable information that can be used to contact or
              identify you. Personally identifiable information may include but
              is not limited to your name, email address, and usage data
              ("Personal Information").
            </p>

            <h4>Log Data</h4>
            <p>
              Like many app operators, we collect information that your device
              sends whenever you use our App ("Log Data"). This Log Data may
              include information such as your device's Internet Protocol ("IP")
              address, device name, operating system version, the time and date
              of your use of the App, and other statistics.
            </p>

            <h4>Cookies</h4>
            <p>
              Cookies are files with a small amount of data, which may include
              an anonymous unique identifier. Cookies are sent to your browser
              from a web site and stored on your device's storage. Like many
              apps, we use "cookies" to collect information. You can instruct
              your device to refuse all cookies or to indicate when a cookie is
              being sent. However, if you do not accept cookies, you may not be
              able to use some portions of our App.
            </p>

            <h4>Agreement with OpenAI Privacy Policy</h4>
            <p>
              By using our App, you automatically acknowledge and accept the Privacy Policy of OpenAI: https://openai.com/policies/privacy-policy.
            </p>
            <h4>Information Transfer to the United States</h4>
            <p>
              By using our Service, you understand and acknowledge that your Personal Information will be transferred from your location to our facilities and servers in the United States.
            </p>
            <h4>For EEA, UK or Swiss users:</h4>
            <p>
              Our legal bases for processing your Personal Information include performance of a contract with you, our legitimate interests, your consent, and compliance with our legal obligations. For data transfers outside of the EEA, Switzerland, and the UK, we will use appropriate safeguards.
            </p>
            <h4>Non-Collection of Data</h4>
            <p>
              We commit not to collect the usage data you send to OpenAI, including your input content and other information.
            </p>
            <h4>Security and Retention</h4>
            <p>
              We implement commercially reasonable measures to protect your Personal Information, but no Internet or email transmission is fully secure. We'll retain your Personal Information for as long as necessary to provide our Service or for other legitimate business purposes. We may also anonymize your Personal Information for research or statistical purposes, in which case we may use this information indefinitely without further notice to you.
            </p>
            <h4>How We Use Your Personal Information</h4>
            <p>
              We use your personal information for the following purposes:

              To provide and improve the App: We use your personal information to provide and improve the App, including to personalize your experience, to troubleshoot problems, and to develop new features.
              To communicate with you: We use your personal information to communicate with you about the App, such as to send you notifications, to respond to your questions, and to provide you with support.
              To market to you: We use your personal information to market to you about our products and services, such as to send you promotional emails and to show you targeted ads.
              To comply with the law: We use your personal information to comply with the law, such as to respond to subpoenas or court orders.

            </p>
            <h4>Your Rights</h4>
            <p>
              You have the following rights with respect to your personal information:

              Access: You have the right to access your personal information that we collect.
              Correction: You have the right to correct any inaccurate or incomplete personal information that we collect.
              Deletion: You have the right to request that we delete your personal information.
              Objection: You have the right to object to our processing of your personal information.
              Portability: You have the right to request that we provide you with a copy of your personal information in a portable format.
              Withdrawal of consent: You have the right to withdraw your consent to our processing of your personal information at any time.

              To exercise any of these rights, please contact us at hello@1stso.com.
            </p>


            <h4>Accuracy</h4>
            <p>
              Services like ChatGPT generate responses by predicting the most likely next words based on a user’s request, and these may not always be factually accurate. You should not rely on the factual accuracy of output from our models. If you notice that the output contains factually inaccurate personal information about you and you would like us to correct the inaccuracy, you may submit a correction request to dsar@openai.com. Due to the technical complexity of our models, we may not be able to correct the inaccuracy. In that case, you may request that we remove your personal information from the model’s output by filling out this form.
            </p>
            <p>
              For information on how to exercise your rights with respect to data we have collected from the internet to train our models, please see this help center article.
            </p>

            <h4>Service Providers</h4>
            <p>
              We may employ third-party companies and individuals to facilitate
              our App, to provide the App on our behalf, to perform App-related
              services or to assist us in analyzing how our App is used.
            </p>
            <p>
              These third parties have access to your Personal Information only
              to perform these tasks on our behalf and are obligated not to
              disclose or use it for any other purpose.
            </p>



            <h4>International Transfer</h4>
            <p>
              Your information, including Personal Information, may be
              transferred to — and maintained on — computers located outside of
              your state, province, country, or other governmental jurisdiction
              where the data protection laws may differ from those in your
              jurisdiction.
            </p>

            <p>
              Your consent to this Privacy Policy followed by your submission of
              such information represents your agreement to that transfer.
            </p>

            <h4>Links to Other Sites</h4>
            <p>
              Our App may contain links to other sites that are not operated by
              us. If you click on a third-party link, you will be directed to
              that third party's site. We strongly advise you to review the
              Privacy Policy of every site you visit.
            </p>
            <p>
              We have no control over, and assume no responsibility for the
              content, privacy policies, or practices of any third-party sites
              or services.
            </p>

            <h4>Children's Privacy</h4>
            <p>
              Our App does not address anyone under the age of 13 ("Children").
              We do not knowingly collect personally identifiable information
              from children under 13. If you are a parent or guardian and you
              learn that your Children have provided us with Personal
              Information, please contact us. If we become aware that we have
              collected Personal Information from a child under age 13 without
              verification of parental consent, we take steps to remove that
              information from our servers.
            </p>

            <h4>Changes to This Privacy Policy</h4>
            <p>
              This Privacy Policy is effective as of March 20,2023 and will
              remain in effect except with respect to any changes in its
              provisions in the future, which will be in effect immediately
              after being posted on this page.
            </p>
            <p>
              We reserve the right to update or change our Privacy Policy at any
              time, and you should check this Privacy Policy periodically. Your
              continued use of the App after we post any modifications to the
              Privacy Policy on this page will constitute your acknowledgment of
              the modifications and your consent to abide and be bound by the
              modified Privacy Policy.
            </p>
            <p>
              If we make any material changes to this Privacy Policy, we will
              notify you either through the email address you have provided us
              or by placing a prominent notice on our website or within the App.
            </p>

            <h4>Contact Us</h4>
            <p>
              If you have any questions about this Privacy Policy, please
              contact us.
            </p>
          </Col>
          <Col></Col>
        </Col>
      </Row>
    </div>
  );
}

export default Privacy;
