import React, { useState, useEffect } from "react";
import { createRoot } from "react-dom/client";
import './index.css';
import App from './App';
import "./i18n"; 
import LanguageContext from "./LanguageContext";

function AppWrapper() {
  const localStorageLanguage = localStorage.getItem("language");
  const [language, setLanguage] = useState(localStorageLanguage || "en");

  useEffect(() => {
    localStorage.setItem("language", language);
  }, [language]);

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      <App/>
    </LanguageContext.Provider>
  );
}

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <AppWrapper />
  </React.StrictMode>
);

