import React from 'react';

import styles from '../styles/design.module.scss';
import Button from './Button';

function Design() {


  const handleClick = () => {
    window.open("https://play.google.com/store/apps/details?id=com.anacc2362193621.OneDo");
  };

  return (
    <>
      <main className={styles.mainContent}>
        <div className={styles.img}>
          <img src="/cube2.png" alt="hero" style={{width: '100%', height: 'auto'}}/>
        </div>
        <div className={styles.container}>
       
          <h5>
            Your AI digital companion for mobile.
          </h5>
          <br/>
          <p>
            Seamlessly blending everyday utility with Artificial Intelligence.
          </p>
          
          <Button src="/play.svg" title="Download Now" href="https://play.google.com/store/apps/details?id=com.anacc2362193621.OneDo" onClick={handleClick} />
          
        </div>
      </main>
    </>
  );
}

export default Design;
