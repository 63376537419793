import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import styles from '../styles/header.module.scss';
import Nav from 'react-bootstrap/Nav';
import Button from './Button';

// Custom NavLink Component
function NavLink({ to, children, external = false }) {
  const location = useLocation();
  const isActive = !external && to === location.pathname;

  if (external) {
    return (
      <a
        href={to}
        target="_blank"
        rel="noopener noreferrer"
        style={{
          fontWeight: 'normal',
          color: 'gray',
          textDecoration: 'none',
          marginLeft: 20
        }}
      >
        {children}
      </a>
    );
  }

  return (
    <Link to={to}>
      <span style={{
        fontWeight: isActive ? 'bold' : 'normal',
        color: isActive ? 'black' : 'gray',
        textDecoration: 'none',
        marginLeft: 20
      }}>
        {children}
      </span>
    </Link>
  );
}


//Header
function Header() {
  const handleClick = () => {
    window.open("https://play.google.com/store/apps/details?id=com.anacc2362193621.OneDo");
  };

  return (
    <Navbar collapseOnSelect expand="lg" className={styles.header}>
      <Container className={styles.navContainer}>

        <Navbar.Brand href="/" className={styles.navbarBrand}>
          <img src="/logo.svg" width={150} height={80} alt="logo" />
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav" className={styles.navbarCollapse}>
          <Nav >
            <NavLink to="/" external={false}>Home</NavLink>
            <NavLink to="/features" external={false}>Features</NavLink>
            <NavLink to="https://medium.com/@ainews01/elevate-your-ai-chatgpt-experience-with-onedo-app-1d2739918e2b" external={true}>About</NavLink>
          </Nav>
        </Navbar.Collapse>





      </Container>

    </Navbar>
  );
}

export default Header;
