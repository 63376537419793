
import Banner from "../component/Banner";
import Brain from "../component/Brain";
import Design from "../component/Design";
import Footer from "../component/Footer";
import Header from "../component/Header";
import Middle from "../component/Middle";
import styles from "../page.module.scss";

export default function Home() {

  return (
    <>

      <div className={styles.main} data-testid="banner">
        <Banner />

      </div>



      <div className={styles.main}>
        <img src="/title1.svg" width={300} height={120} alt="logo" />
        <br />
        <div className={styles.gameList} data-testid="game-list">
          <Middle />

        </div>

        <Brain />
        <Design />

      </div>



    </>
  );
}
