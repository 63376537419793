import Card from "./Card";
import { Link } from "react-router-dom";

function Middle() {
    const cardsData = [
      { imgSrc: "/1.png", title: "AI Toolkits", href: "/features" },
      { imgSrc: "/2.png", title: "AI Staff", href: "/features" },
      { imgSrc: "/3.png", title: "AI-Powered Design", href: "/features" },
    ];
  
    return (
      <>
        {cardsData.map((card, index) => (
          <Link to={card.href} key={index}>
            <Card
              imgSrc={card.imgSrc}
              title={card.title}
              Slug={card.Slug}
            />
          </Link>
        ))}
      </>
    );
}
  
export default Middle;
