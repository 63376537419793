
import Button from "../component/Button";
import styles from "../styles/feature.module.scss";
import React from "react";

const data = [
  {
    titleSrc: "/title3.svg",
    titleAlt: "hero",
    description: "Compatible with over 18,000 models of various smartphones, tablets, and other devices.",
    imageSrc: "/4.svg",
    imageAlt: "brain",
  },
  {
    titleSrc: "/title4.svg",
    titleAlt: "hero",
    description: "Meet your daily and work needs with our app.",
    imageSrc: "/7.svg",
    imageAlt: "brain",
  },
  {
    titleSrc: "/title6.svg",
    titleAlt: "hero",
    description: "Create stunning images in minutes.",
    imageSrc: "/8.jpg",
    imageAlt: "brain",
  },
  {
    titleSrc: "/title5.svg",
    titleAlt: "hero",
    description: "Virtual AI employee, boosting your work efficiency by a hundredfold.",
    imageSrc: "/people.png",
    imageAlt: "brain",
  }
];

const Features = () => {
  const handleClick = () => {
    window.open("https://play.google.com/store/apps/details?id=com.anacc2362193621.OneDo");
  };

  return (
    <>


      <div className={styles.brainContainer}>
      
        {data.map((item, index) => (
          <React.Fragment key={index}>
            <div className={styles.largeTitle}>
              <img className={styles.largeTitleImage} src={item.titleSrc} alt={item.titleAlt} width={300} height={120} />
            </div>

            <h4 className={styles.title}>{item.description}</h4>
            <div className={styles.brainimg}>
              <img src={item.imageSrc} alt={item.imageAlt} width={'100%'} style={{ borderRadius: 20 }} />
            </div>

            
          </React.Fragment>
        ))}

      </div>
    </>
  );
};

export default Features;

