import React from 'react';
import styles from '../styles/button.module.scss';

// Reusable Buttons
const Button = ({ title, src, onClick }) => (
  <button
    className={styles.button}
    onClick={onClick}
  >
    <img src={src} alt="play" width="25" height="25" />
    <span style={{marginLeft:10}}>{title}</span>
  </button>
);

export default Button;


