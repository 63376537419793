import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./translations/en.json";
import zh from "./translations/zh.json";
import de from "./translations/de.json";
import dt from "./translations/dt.json";
import fr from "./translations/fr.json";
import jp from "./translations/jp.json";
import ko from "./translations/ko.json";
import sp from "./translations/sp.json";
import zf from "./translations/zf.json";


i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: en },
      zh: { translation: zh },
      de: { translation: de },
      dt: { translation: dt },
      fr: { translation: fr },
      jp: { translation: jp },
      ko: { translation: ko },
      sp: { translation: sp },
      zf: { translation: zf },
    },
    lng: "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;